import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import PaymentTile from "../../components/PaymentTile";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`Migrating to newer versions of software might seem unimportant. These upgrades often require reworking existing infrastructure, which can be costly. However, the benefits of upgrading to PHP 7 outweigh the costs so significantly that you should not hesitate to do so. In fact, it would be irresponsible to continue without upgrading, as you’ll see below.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colSm={8} colMd={5} colLg={5} mdxType="Column">
        <PaymentTile ammount={2} mdxType="PaymentTile" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      